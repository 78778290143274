/** @module Permissions */

import { compact, flatten } from 'lodash';

// Utils
import AccessManager from 'access-manager';
const accessManager = new AccessManager();

// Constants
import { SPOTME_ORG_ID } from 'libs/utils/constants';

/**
 * Determine if a user has a permission given her list of roles and a context
 * @param  {Object[]}  roles list of roles that the user has (in the old format)
 * @param  {string}  roles.role name of the role (i.e. admin)
 * @param  {string}  roles.type type of the role (global_permission or event_permission or account_permission)
 * @param  {string}  [roles.event_id] if the role is in the context of an event
 * @param  {string}  [roles.org_id] if the role is in the context of an org
 * @param  {string}  attributedRoles.attrCtx1 attribute 1 determining the context in which the user has the permission
 * @param  {string}  permissionName name of the permission for which we want to know if the user has access
 * @param  {Object}  context context determining if the user has permission
 * @param  {string}  [context.event_id] context attribute event_id if in the context of an event
 * @param  {string}  [context.org_id] context attribute org_id if in the context of an org
 * @return {Boolean} true if the user has a role enabling the permission and the role is either a global role or
 * every attribute required for the role are both present in the attributed role and the context and are all equal
 * false otherwise
 */
export function hasPermission(roles, permission, context = {}) {
    const hasPermission = accessManager.hasPermission(
        mapRolesToAMRoleFormat(roles),
        permission,
        context
    );

    return hasPermission;
}


/**
 * Determine if a user has a permission given her list of roles and a context
 * @param  {Object[]}  roles list of roles that the user has (in the old format)
 * @param  {string}  roles.role name of the role (i.e. admin)
 * @param  {string}  roles.type type of the role (global_permission or event_permission or account_permission)
 * @param  {string}  [roles.event_id] if the role is in the context of an event
 * @param  {string}  [roles.org_id] if the role is in the context of an org
 * @param  {object}  requiredRole required role in old format
 * @param  {string}  requiredRole.role name of the role for which we want to know if the user has access
 * @param  {string}  [requiredRole.event_id] context attribute event_id if in the context of an event
 * @param  {string}  [requiredRole.org_id] context attribute org_id if in the context of an org
 * @return {Boolean} true if the user has a role enabling the permission and the role is either a global role or
 * every attribute required for the role are both present in the attributed role and the context and are all equal
 * false otherwise
 */
export function canAssignRole(roles, requiredRole) {
    const role = mapRolesToAMRoleFormat([requiredRole])[0];
    const canAssignRole = accessManager.isRoleInherited(
        mapRolesToAMRoleFormat(roles),
        role
    );

    return canAssignRole;
}

/**
 * The new accessManager uses a new Role Format with a different name, this function converts it to the new format.
 * @param {Array<Object>} roles
 * @returns {Array<Object>} new role format
 */
function mapRolesToAMRoleFormat(roles) {
    return compact(roles.map(role => {
        // i.e. { type: 'global_permission', role: 'beta_tester' } => 'global_beta_tester'
        if (!role) return;
        const name = role.type.replace('permission', '') + role.role;

        return {
            role: name,
            event_id: role.event_id,
            org_id: role.org_id,
        };
    }));
}

/**
 * Given a feature flag, this method returns all the current
 * user's organizations that present that flag.
 *
 * @param {object[]} orgs a list of organizations
 * @param {string} flag the name of the feature to check
 *
 * @return {object[]} a list of organizations
 */
export function getOrgsByFeatureFlag(orgs, flag) {
    return orgs.filter(o => o[flag]);
}

/**
 * Check if current user matches one of the provided conditions (OR), which can be one of those.
 * Several conditions can be put into one to match several conditions at the same time.
 * If no conditions are provided true will be returned.
 *
 * `{ userId: id }` matches if userId is id
 * `{ isSpotme: true}` matches if user belongs to spotme org
 * `{ globalRole: role }`
 * `{ eventRole: role, [eventId: eid] }` eventId is optional, if not provided, current event is used
 * `{ eventId: eid }` has any role in given event
 * `{ orgRole: role, orgId: orgId }`
 * `{ orgId: orgId }` has any role in given organisation
 * `{ orgRole: role }` has role in any organisation
 *
 * @param {string} eid - The event ID to check against.
 * @param {Object} user - The user object to check roles for.
 * @param {...Array} roles - The roles to check against, can be multiple arrays of roles.
 *
 * @returns {boolean} - Returns true if the user matches any of the specified roles, otherwise false.
 *
 * @deprecated Use hasPermission instead
 */
export function matchesRoles(eid, user, ...roles) {
    if (!user) {
        return false;
    }

    const conditions = compact(flatten(roles));
    return !conditions.length || conditions.some(condition => checkCondition(eid, user, condition));
}

/**
 * Checks if a user meets the specified condition role.
 *
 * @param {string} eid - The event ID.
 * @param {Object} user - The user object.
 * @param {Array} user.roles - The roles assigned to the user.
 * @param {Array} user.orgs - The organizations the user belongs to.
 * @param {Object} conditionRole - The condition role to check against.
 * @param {string} [conditionRole.userId] - The specific user ID to match.
 * @param {string} [conditionRole.globalRole] - The global role to match.
 * @param {string} [conditionRole.orgRole] - The organization role to match.
 * @param {string} [conditionRole.orgId] - The organization ID to match.
 * @param {string} [conditionRole.eventRole] - The event role to match.
 * @param {boolean} [conditionRole.isSpotme] - Whether the user should belong to the SPOTME organization.
 * @param {string} [conditionRole.someRole] - Some other role to match.
 * @param {string} [conditionRole.eventId=eid] - The event ID to match.
 *
 * @returns {boolean} True if the user meets the condition role, false otherwise.
 *
 * @private
 */
function checkCondition(eid, user, conditionRole) {
    const userRoles = user.roles || [];
    const userOrgs = user.orgs || [];

    const {
        userId,
        globalRole,
        orgRole,
        orgId,
        eventRole,
        isSpotme,
        someRole,
        eventId = eid
    } = conditionRole;

    if (userId && user._id === userId) {
        return true;
    }

    if (isSpotme && userOrgs.some(org => org._id === SPOTME_ORG_ID)) {
        return true;
    }

    if (globalRole && userRoles.some(role => role.type === 'global_permission' && role.role === globalRole)) {
        return true;
    }

    if (eventRole && userRoles.some(role => role.type === 'event_permission' && role.role === eventRole && role.event_id === eventId)) {
        return true;
    }

    if (!eventRole && conditionRole.eventId && userRoles.some(role => role.type === 'event_permission' && role.event_id === eventId)) {
        return true;
    }

    if (orgRole && userRoles.some(role => role.type === 'account_permission' && role.role === orgRole && (!orgId || role.org_id === orgId))) {
        return true;
    }

    if (!orgRole && orgId && userRoles.some(role => role.type === 'account_permission' && role.org_id === orgId)) {
        return true;
    }

    if (someRole && userRoles.some(role => role.type === 'account_permission' && role.role === someRole)) {
        return true;
    }

    return false;
}
