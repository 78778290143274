// Utils
import { getRouteHref } from 'libs/utils/modules';
import { interpolateUrl } from 'libs/utils/url';
import { toSnakeCase } from 'libs/utils/string';

/**
 * @typedef {Object} CellConfig
 * @property {string} field the field to render
 * @property {string} [label] the human readable label for the field
 * @property {string} [sortable] the sortable string for the BE to sort by
 * @property {object} [component] the component to render the field
 * @property {function} [format] the function to format the field
 */

const CSS_CLASS_FP_STATUS = {
    'cancelled': 'erased',
    'hidden': 'masked'
};

/**
 * Renders the cell with the given field and item
 *
 * @param {CellConfig} cellConfig the configuration for the cell
 * @param {object} item the FP typed item
 *
 * @returns {string} a human readable version of the given field
 */
export function render(cellConfig, item) {
    if (cellConfig.format) {
        if (typeof cellConfig.format === 'function') {
            return cellConfig.format(item);
        } else {
            console.warn('cellConfig.format is not a function', cellConfig.format);
        }
    }

    return item[cellConfig.field] || '×';
}

/**
 * Determines the CSS classes to print on the given cell
 *
 * @param {CellConfig} cellConfig the configuration for the cell
 * @param {object} [item] the FP typed item
 *
 * @returns {string[]} a list of CSS classes to use for the cell
 */
export function cellClasses(cellConfig, item) {
    const classes = [cellConfig.cssClass || toSnakeCase(cellConfig.field)];
    const status = item?.status || item?.fp_status;

    if (Object.keys(CSS_CLASS_FP_STATUS).includes(status)) {
        classes.push(CSS_CLASS_FP_STATUS[status]);
    }

    if (
        !cellConfig.hasOwnProperty('component') &&
        !cellConfig.hasOwnProperty('actions') &&
        !cellConfig.noTruncate
    ) {
        classes.push('ellipsis');
    }

    if (cellConfig.hasOwnProperty('actions')) {
        classes.push('actions');
    }

    return classes;
}

/**
 * Gets the sorting classes for the given cell
 *
 * @param {CellConfig} cellConfig the configuration for the cell
 * @param {'ASC'|'DESC'} sort the current sort direction
 * @param {string} sortBy the current sort field
 *
 * @returns {string[]} a list of sorting css classes
 */
export function getCellSortClass(cellConfig, sort, sortBy) {
    if (!cellConfig.sortable) {
        return;
    }

    const classes = ['sortable'];

    if (cellConfig.field === sortBy) {
        classes.push(`sort-${sort.toLowerCase()}`);
    }

    return classes;
}

/**
 * Sets the sortable field and reload the collection
 *
 * @param {CellConfig} cellConfig the configuration for the cell
 * @param {string} sort the current sort direction
 * @param {string} sortField the current sort field
 */
export function setSortable(cellConfig, sort, sortField) {
    if (!cellConfig.sortable) {
        return { sort, sortField };
    }

    sort = sortField === cellConfig.sortable && sort === 'ASC' ? 'DESC' : 'ASC';
    sortField = cellConfig.sortable;
    return { sort, sortField };
}

/**
 * Compiles a link in the context of the given cell
 *
 * @param {GenericListColumnConfig} link the link for the cell
 * @param {object} item the FP typed item
 * @param {object} module the module to get the link from
 * @param {object} event the event to get the link for
 *
 * @returns {string} a compiled version of the link for this cell
 */
export function cellLink(itemLink, item, module, event) {
    const params = {};
    if (itemLink.params) {
        for (const param of Object.keys(itemLink.params)) {
            const itemValue = item[itemLink.params[param]];
            if (!itemValue) {
                return null;
            }
            params[param] = itemValue;
        }
    }

    const href = getRouteHref(module, itemLink.controllerKey, {
        _id: item._id,
        eventId: event._id,
        ...params
    });

    if (!itemLink.searchParams) {
        return href;
    }

    const q = new URLSearchParams();
    for (const [key, value] of Object.entries(itemLink.searchParams)) {
        q.set(key, interpolateUrl(value, item));
    }

    return `${href}?${q.toString()}`;
}
