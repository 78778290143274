/* eslint-disable
    eqeqeq,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
let { _ } = require('underscore');
let Markdown = require('marked');

// # # # #

let DEFAULT_FORMATTER_KIND = 'default';

let EXCEL_DATETIME_MATCHER_REGEX =
    /^(\d\d)\/(\d\d)\/(\d{4}) (\d\d:\d\d)$/;

// # # # #

let sanitizeExcelParsedDateFormatFn = function(datetime) {
    if (typeof datetime !== 'string') { return datetime; }
    let m = datetime.match(EXCEL_DATETIME_MATCHER_REGEX);

    if (!((m != null ? m.length : undefined) >= 5)) { return datetime; }
    return `${m[3]}/${m[2]}/${m[1]} ${m[4]}`;
};

let processStringListOrArrayFn = function(listStr, kindOptions, eventMetadata) {
    let arr;

    if (!_.isString(listStr) || !~listStr.indexOf(',')) { arr = listStr; }
    if (!arr) { arr = listStr.split(','); }
    switch ((kindOptions != null ? kindOptions.item_kind : undefined)) {
        case 'number': return arr.map(parseFloat);
        case 'boolean': return arr.map(parseBoolean);
        case 'nested-object': return arr.map(item => processNestedObjectFn(item, kindOptions, eventMetadata));
        default: return arr;
    }
};

let processCustom = (value, kindOptions) => value;

var processNestedObjectFn = function(obj, kindOptions, eventMetadata) {
    if (!_.isObject(obj) || !!_.isArray(obj)) { return obj; } // just to be sure
    return _.chain(obj)
        .map(function(v, k) {
            if (!(kindOptions != null ? kindOptions.fields : undefined)) { return [k, v]; }
            // handle both array and object form
            let fieldDescriptor = kindOptions.fields[k] || _.findWhere(kindOptions.fields, { field: k });

            if (!fieldDescriptor) { return [k, v]; }
            let kind = fieldDescriptor.kind || DEFAULT_FORMATTER_KIND;

            return [k, getFormatterFnForKind(kind)(v, fieldDescriptor.kind_options, eventMetadata)]; })
        .object()
        .value();
};

let processExternalFn = function(val, kindOptions, eventMetadata) {
    // don't do any formatting unless we're dealing with targets-exceptions; they require special processing!
    if ((kindOptions.type !== 'targets-exceptions') || !_.isArray(val)) { return val; }

    return parseTargetsExceptions(val, kindOptions, eventMetadata);
};

function parseTargetsExceptions(val, kindOptions, eventMetadata) {
    let personMetadata;

    // dealing with targets-exceptions list. format each mapping according to person metadata
    if (_.isEmpty(personMetadata = eventMetadata.person)) { return val; }
    return val.map(targetRule =>
        _.chain(targetRule)
            .pairs()
            .map(function(targetCondPair) {
            // find the field in person data, format according to its kind and kind_options
                let _personMetadataField = personMetadata[targetCondPair[0]];

                if (_.isEmpty(_personMetadataField)) { return targetCondPair; }
                let _fieldFormatterFn = getFormatterFnForKind(_personMetadataField.kind);

                targetCondPair[1] =
                    _fieldFormatterFn(targetCondPair[1], _personMetadataField.kind_options, eventMetadata);
                return targetCondPair; }).object()
            .value()
    );
}

var parseBoolean = function(string) {
    switch (string.toLowerCase()) {
        case 'true': case 'yes': case '1':
            return true;
        case 'false': case 'no': case '0': case null:
            return false;
        default:
            return Boolean(string);
    }
};

let formatters = [
    ['html', function(html) { if (html == null) { html = ''; } return Markdown(html); }],
    ['timestamp', sanitizeExcelParsedDateFormatFn],
    ['list', processStringListOrArrayFn],
    ['nested-object', processNestedObjectFn],
    ['external', processExternalFn],
    ['boolean', parseBoolean],
    ['number', parseFloat],
    ['targets-exceptions', parseTargetsExceptions],
    ['custom', processCustom],
    [DEFAULT_FORMATTER_KIND, 'text', function(v) {
        if (_.isString(v)) { return v; } else { return String(v); }
    }]
];

let formatterCache = {};

formatters.map(function(formatterList) {
    let fn = _.last(formatterList);
    let keys = _.initial(formatterList);

    return keys.forEach(k => formatterCache[k] = fn);
});

var getFormatterFnForKind = function(kind) {
    if (kind == null) { kind = 'default'; }
    return formatterCache[kind] || formatterCache[DEFAULT_FORMATTER_KIND];
};


// # # # #

angular.module('backstage.services')

    .factory('fieldFormatterService', [
        'eventService', 'metadataService',
        (eventService, metadataService) =>

            ({
                applyFormatter(val, kind, kindOptions) {
                    if (kindOptions == null) { kindOptions = {}; }
                    let eventMetadata = metadataService.getCachedMetadata(__guard__(eventService.getCurrentEvent(), x => x.id)) || {};

                    return getFormatterFnForKind(kind)(val, kindOptions, eventMetadata);
                }
            })


    ]);

function __guard__(value, transform) {
    return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
