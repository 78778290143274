// Utils
import jsonLogic from 'json-logic-js';
import { isNull } from 'lodash';

/**
 * Evaluates whether the given conditions are met based on the provided context.
 *
 * @param {Object} conditions - The JSONlogic conditions to evaluate.
 * @param {Object} context - The context in which to evaluate the conditions.
 *
 * @returns {boolean} - Returns true if the conditions are met, otherwise false.
 */
export function areConditionsMet(conditions, context) {
    return jsonLogic.apply(conditions, context);
}

/**
 * Recursively removes all undefined values from the given object
 *
 * @param {object|null} [object={}] the object to clean
 *
 * @returns {object} the object without undefined values
 */
export function clean(object = {}) {
    if (typeof object === 'object' && object !== null && !Array.isArray(object)) {
        Object.keys(object).forEach(k => {
            if (typeof object[k] === 'undefined') {
                delete object[k];
            }

            if (typeof object[k] === 'object') {
                object[k] = clean(object[k]);
            }
        });

        return object;
    }

    return {};
}

/**
 * Deeply extends an object with properties from additional objects.
 *
 * This function recursively merges properties from source objects into the target object.
 * It handles various data types including arrays, objects, strings, dates, and functions.
 *
 * @param {Object} obj - The target object to extend.
 * @param {...Object} others - One or more source objects to extend the target object with.
 *
 * @returns {Object} - The extended target object.
 */
export function deepExtend(obj, ...others) {
    const parentRE = /#{\s*?_\s*?}/;

    others.forEach(source => {
        Object.keys(source ?? {}).forEach(prop => {
            obj[prop] = mergeProperty(obj[prop], source[prop], parentRE);
        });
    });

    return obj;
}

/**
 * Merges a property from a source object into a target object property based on specific conditions.
 *
 * @param {any} objProp - The property from the target object.
 * @param {any} sourceProp - The property from the source object.
 * @param {RegExp} parentRE - A regular expression used to test and replace strings.
 *
 * @returns {any} - The merged property.
 *
 * @private
 */
function mergeProperty(objProp, sourceProp, parentRE) {
    if (sourceProp === undefined || typeof objProp === 'function' || sourceProp === null || sourceProp instanceof Date) {
        return sourceProp;
    } else if (typeof sourceProp === 'string' && parentRE.test(sourceProp)) {
        return typeof objProp === 'string' ? sourceProp.replace(parentRE, objProp) : sourceProp;
    } else if (Array.isArray(sourceProp) || Array.isArray(objProp)) {
        return mergeArrays(objProp, sourceProp);
    } else if (typeof sourceProp === 'object' || typeof objProp === 'object') {
        return mergeObjects(objProp, sourceProp);
    } else {
        return sourceProp;
    }
}

/**
 * Merges two properties, which can be arrays, into one.
 *
 * @param {Array|any} objProp - The target property which will be merged with the source property.
 * @param {Array|any} sourceProp - The source property to merge into the target property.
 *
 * @returns {Array|any} - The merged result. If both properties are arrays, it returns a unique array.
 *                        If only the source property is an array, it returns a unique array.
 *                        Otherwise, it returns the source property.
 * @private
 */
function mergeArrays(objProp, sourceProp) {
    if (Array.isArray(sourceProp) && Array.isArray(objProp)) {
        return deepExtend(objProp, sourceProp).filter(item => !isNull(item));
    } else {
        return sourceProp;
    }
}

/**
 * Merges two objects. If both properties are objects, it performs a deep merge.
 * Otherwise, it returns the source property.
 *
 * @param {Object} objProp - The target object property.
 * @param {Object} sourceProp - The source object property.
 *
 * @returns {Object} - The merged object or the source property.
 *
 * @private
 */
function mergeObjects(objProp, sourceProp) {
    return typeof sourceProp === 'object' && typeof objProp === 'object'
        ? deepExtend({ ...objProp }, sourceProp)
        : sourceProp;
}

// Global declaration to make it available for https://github.com/Spotme/pkg-forms/blob/dev/src/bstg-source/bstg-results/fb-state.js#L106
// Read only to make it no overiden by pkg-person old code
// The if is needed because htis seems to be initialized twice locally.
if (!window.deepExtend) {
    Object.defineProperty(window, 'deepExtend', {
        value: deepExtend,
        writable: false
    });
}
