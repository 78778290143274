// Utils
import BaseService from './base-service';
import { get, set, cloneDeep } from 'lodash';
import dictree from 'dictree';

import { API_BASE_PATH, DOC_BY_ID_ENDPOINT } from 'libs/utils/constants';

/**
 * API endpoint for creating a document by ID. Interpolations: `{{eventId}}`, `{{fpType}}`.
 * @const CREATE_DOC_ENDPOINT
 * @private
 */
const CREATE_DOC_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/doc/{{fpType}}`;

/**
 * API endpoint for updating a document by ID. Interpolations: `{{eventId}}`, `{{fpType}}`, `{{docId}}`.
 * @const UPDATE_DOC_ENDPOINT
 * @private
 */
const UPDATE_DOC_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/doc/{{fpType}}/{{docId}}`;

/**
 * API endpoint for getting a document by ID. Interpolations: `{{eventId}}`.
 * @const COMPILE_SETTINGS_ENDPOINT
 * @private
 */
const COMPILE_SETTINGS_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/compile/settings`;

/**
 * Settings doc ID
 * @const SETTINGS_DOC_ID
 * @private
 */
const SETTINGS_DOC_ID = 'settings';

/**
 * Registry doc ID
 * @const REGISTRY_DOC_ID
 * @private
 */
const REGISTRY_DOC_ID = 'registry';

/**
 * Settings overidde doc ID
 * @const OVERRIDE_SETTINGS_DOC_ID
 * @private
 */
const OVERRIDE_SETTINGS_DOC_ID = 'settings_changes';


/**
 * Provides utils for getting or saving settings information.
 *
 * @example
 * import SettingsService from 'libs/services/settings';
 * ...
 * const settings = new SettingsService();
 */
export default class SettingsService extends BaseService {

    /**
     * Gets all the settings of the specified event
     *
     * @param {string} eventId the ID of the event
     * @param {Number} [maxDuration=5000] the max duration of the cache in milliseconds
     *
     * @returns {Promise<any|undefined>} the event's settings
     */
    async getSettings(eventId, maxDuration = 5000) {
        const url = this.buildUrl(DOC_BY_ID_ENDPOINT, {
            eventId, docId: SETTINGS_DOC_ID
        });
        const { data } = await this.getCached(url, undefined, maxDuration);
        return data;
    }

    /**
     * Gets the setting specified by the path
     *
     * @param {string} eventId the ID of the event
     * @param {string} settingPath the path of the setting to return
     * @param {Number} [maxDuration=5000] the max duration of the cache in milliseconds
     *
     * @returns {Promise<any|undefined>} the requested setting or undefined
     */
    async getSetting(eventId, settingPath, maxDuration = 5000) {
        const settings = await this.getSettings(eventId, maxDuration);
        return get(settings, settingPath);
    }

    /**
     * Save setting values specified by the path
     * @param {string} eventId
     * @param {string} settingPath
     * @param {Object} value
     */
    async saveSetting(eventId, settingPath, value) {
        const cacheBust = new Date().getTime();

        // step 1: get the full settings doc
        const urlSettings = this.buildUrl(DOC_BY_ID_ENDPOINT, {
            eventId, docId: SETTINGS_DOC_ID
        });
        const { data: existing } = await this.get(urlSettings, {
            params: { _: cacheBust }
        });

        // copy settings to build the diff
        const newSettings = cloneDeep(existing);
        // apply change
        set(newSettings, settingPath, value);

        // step 2: get the existing override
        const urlSettingsOverride = this.buildUrl(DOC_BY_ID_ENDPOINT, {
            eventId, docId: OVERRIDE_SETTINGS_DOC_ID
        });
        let createOverride = false;
        let { data: override } = await this.get(urlSettingsOverride, {
            params: { _: cacheBust }
        }).catch(err => {
            if (err?.response?.status === 404) {
                createOverride = true;
                const bit = { data: {
                    _id: OVERRIDE_SETTINGS_DOC_ID,
                    fp_bit_priority: 'highest',
                    fp_owner: 'private',
                } };

                if (settingPath && value) {
                    bit[settingPath] = value;
                }

                return bit;
            }
            throw err;
        });


        // step 3: build the diff
        override = dictree.build_patch(newSettings, existing, override);

        // step 4: save the override and recompile
        if (createOverride) {
            const url = `${CREATE_DOC_ENDPOINT}?keep_id=1`;
            const createOverrideUrl = this.buildUrl(url, {
                eventId, fpType: 'settings_changes'
            });
            await this.post(createOverrideUrl, { doc: override });
        } else {
            const updateOverrideUrl = this.buildUrl(UPDATE_DOC_ENDPOINT, {
                eventId, docId: OVERRIDE_SETTINGS_DOC_ID, fpType: 'settings_changes'
            });
            await this.put(updateOverrideUrl, { doc: override });
        }

        const compileUrl = this.buildUrl(COMPILE_SETTINGS_ENDPOINT, {
            eventId
        });
        return await this.post(compileUrl, {});
    }

    /**
     * Gets the registry of the specified event
     *
     * @param {string} eventId the ID of the event
     * @param {Number} [maxDuration=5000] the max duration of the cache in milliseconds
     *
     * @returns {Promise<any|undefined>} the event's registry
     */
    async getRegistry(eventId, maxDuration = 5000) {
        const url = this.buildUrl(DOC_BY_ID_ENDPOINT, {
            eventId, docId: REGISTRY_DOC_ID
        });
        const { data } = await this.getCached(url, undefined, maxDuration);
        return data;
    }

    /**
     * Gets the registry settings specified by the key
     *
     * @param {string} eventId the ID of the event
     * @param {string} registryKey the key of the registry to return
     * @param {Number} [maxDuration=5000] the max duration of the cache in milliseconds
     *
     * @returns {Promise<any|undefined>} the requested registry or undefined
     */
    async getRegistrySettings(eventId, registryKey, maxDuration = 5000) {
        const registry = await this.getRegistry(eventId, maxDuration);
        return get(registry, `settings.${registryKey}`);
    }

    /**
     * Gets the registry nav specified by the key
     *
     * @param {string} eventId the ID of the event
     * @param {string} registryKey the key of the registry to return
     * @param {Number} [maxDuration=5000] the max duration of the cache in milliseconds
     *
     * @returns {Promise<any|undefined>} the requested registry or undefined
     */
    async getRegistryNav(eventId, registryKey, maxDuration = 5000) {
        const registry = await this.getRegistry(eventId, maxDuration);
        return get(registry, `navs.${registryKey}`);
    }
}
