"use strict";

// formTooltipAndHelp

var FormTooltipCtrl = /* @ngInject */function FormTooltipCtrl($scope) {
  var vm;
  $scope.vm = vm = {};
  vm.getHelpTooltip = function () {
    return $scope.fieldDescriptor.tooltip || 'Click for help';
  };
};
FormTooltipCtrl.$inject = ["$scope"];
module.exports = /* @ngInject */function () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      fieldDescriptor: '='
    },
    controller: FormTooltipCtrl,
    template: "            <span class=\"form-tooltip\">\n                <a ng-if=\"::fieldDescriptor.tooltip && !fieldDescriptor.help\"\n                class=\"icon-info-sign\"\n                strap-tooltip\n                title=\"{{::fieldDescriptor.tooltip}}\"\n                data-placement=\"right\"\n                toggle=\"tooltip\"></a>\n            </span>            "
  };
};