import { get, set } from 'lodash';

/**
 * @typedef CategoryField
 * @prop {string} field
 * @prop {Record<string,unknown>} definition
 * @prop {number} _rev
 *
 * @typedef {Object} Category
 * @prop {string} category
 * @prop {CategoryField[]} fields
 * @prop {boolean} _to_delete
 */

/**
 * @typedef {object} State
 * @prop {Category[]} categories fields ordered by category
 * @prop {boolean} changed has metadata for fp_type changed
 * @prop {string} eid
 * @prop {Record<string,unknown>} eventDoc
 * @prop {string|undefined} focused field to focus (after being created or moved to another category)
 * @prop {string[]} representations keys of representation field
 * @prop {Record<string,string>} representationFields
 * @prop {Record<string,unknown>} settings
 * @prop {{id:string,label:string}[]} typeList
 */

/** @param {Category['fields']} fields */
const sortFieldsByOrder = (fields) => {
    const getOrder = d => Number.isInteger(d.definition.order)
        ? d.definition.order
        : Infinity;
    return fields.sort((a, b) => getOrder(a) > getOrder(b) ? 1 : -1);
};

/** @param {Category[]} categories */
const sortCategories = (categories) => {
    const getCategoryOrder = d => d.fields[0]?.definition.order || Infinity;
    return categories.sort((a, b) => getCategoryOrder(a) > getCategoryOrder(b) ? 1 : -1);
};

/** @param {Record<string,unknown>} type */
const getCategoriesFromMetadata = (type) => {
    const categoryMap = {};
    for (const [key, value] of Object.entries(type)) {
        if (!value) { continue; }
        const category = value.category || 'other';
        if (!categoryMap[category]) { categoryMap[category] = []; }
        if (!key.startsWith('_')) {
            // set kind as "text" if undefined
            if (!value.kind) { value.kind = 'text'; }
            categoryMap[category].push({ field: key, definition: value, _rev: Math.random() });
        }
    }
    const categories = [];
    for (const [category, fields] of Object.entries(categoryMap)) {
        categories.push({
            category,
            fields: sortFieldsByOrder(fields),
        });
    }
    return sortCategories(categories);
};

/** @type {(category: Category) => number} */
const getMaxCategoryOrder = (category) => {
    let order = 0;
    for (const { definition } of category.fields) {
        if (definition.order && definition.order > order) {
            order = definition.order;
        }
    }
    return order;
};

export default {
    /** @type {State} */
    state: {
        categories: [],
        changed: false,
        eid: undefined,
        eventDoc: undefined,
        focused: undefined,
        representations: [],
        representationFields: {},
        typeList: [],
        settings: undefined,
    },

    mutations: {
        /**
         * @param {State} state
         * @param {object} args
         * @param {Record<string,unknown>} args.type
         * @param {string} args.eid
         */
        setTypeMetadata(state, { type, eid, representations }) {
            state.representations = representations;
            state.eid = eid;
            state.changed = false;
            state.categories = getCategoriesFromMetadata(type);
            for (const key of representations) {
                const value = type[key];
                if (!value) { continue; }
                state.representationFields[key] = value;
            }
        },

        /**
         * @param {State} state
         * @param {Record<string,unknown>} eventDoc
         */
        setMetadataEventDoc(state, eventDoc) {
            state.eventDoc = eventDoc;
        },

        /**
         * @param {State} state
         * @param {Record<string,unknown>} settings
         */
        setMetadataSettings(state, settings) {
            state.settings = settings;
        },

        /**
         * @param {State} state
         * @param {{id:string,label:string}[]} typeList
         */
        setMetadataTypeList(state, typeList) {
            state.typeList = typeList;
        },

        /**
         * @param {State} state
         * @param {string} newCategory
         */
        addMetadataCategory(state, newCategory) {
            if (newCategory) {
                state.categories.push({
                    category: newCategory,
                    fields: [],
                });
            }
        },

        /**
         * @param {State} state
         * @param {string} toCategory
         */
        addMetadataField(state, toCategory = 'other') {
            const fieldName = 'NEW_' + state.categories.reduce((r, d) => r + d.fields.length, 0);
            const categories = [...state.categories];
            const category = categories.find(d => d.category === toCategory);
            category.fields.push({
                field: fieldName,
                definition: {
                    category: toCategory,
                    kind: 'text',
                    order: getMaxCategoryOrder(category) + 1,
                    is_custom_field: true,
                },
                _rev: Math.random(),
            });
            state.categories = categories;
            state.focused = fieldName;
        },

        resetFocusedField(state) {
            state.focused = undefined;
        },

        /**
         * @param {State} state
         * @param {object} args
         * @param {number} args.categoryIndex
         * @param {CategoryField[]} args.fields
         */
        reorderMetadataFields(state, { categoryIndex, fields }) {
            state.categories[categoryIndex].fields = fields;
            state.changed = true;
        },

        setFieldOnTopOfCategory(state, { categoryIndex, fieldIndex }) {
            const fields = state.categories[categoryIndex].fields;
            fields.unshift(fields.splice(fieldIndex, 1)[0]);
            state.focused = fields[0].field;
            state.changed = true;
        },

        /**
         * @param {State} state
         * @param {Category[]} categories
         */
        reorderMetadataCategories(state, categories) {
            let i = 0;
            for (const category of categories) {
                for (const field of category.fields) {
                    field.definition.order = i;
                    i++;
                }
            }
            state.categories = categories;
            state.changed = true;
        },

        /**
         * @param {State} state
         * @param {object} args
         * @param {number} args.categoryIndex
         * @param {number} args.fieldIndex
         * @param {import('lodash').PropertyPath} args.path
         * @param {unknown} args.value
         */
        updateMetadataField(state, { categoryIndex, fieldIndex, path, value }) {
            const field = state.categories[categoryIndex].fields[fieldIndex];
            if (get(field, path) !== value) {
                state.categories[categoryIndex].fields[fieldIndex] = set(field, path, value);
                state.categories[categoryIndex].fields[fieldIndex]._rev = Math.random();
                state.changed = true;
            }
        },

        /**
         * @param {State} state
         * @param {object} args
         * @param {number} args.categoryIndex
         * @param {number} args.fieldIndex
         * @param {string} args.categoryName
         */
        moveMetadataFieldToCategory(state, { categoryIndex, fieldIndex, categoryName }) {
            const categories = [...state.categories];
            const category = categories.find(d => d.category === categoryName);
            const field = {
                field: categories[categoryIndex].fields[fieldIndex].field,
                definition: {
                    ...categories[categoryIndex].fields[fieldIndex].definition,
                    order: getMaxCategoryOrder(category) + 1,
                    category: categoryName === 'other' ? undefined : categoryName,
                },
            };
            categories[categoryIndex].fields = categories[categoryIndex].fields
                .filter((_, i) => i !== fieldIndex);

            category.fields.push(field);
            state.categories = categories;
            state.focused = field.field;
            state.changed = true;
        },

        /**
         * @param {State} state
         * @param {object} args
         * @param {String} args.oldCategory
         * @param {string} args.newCategory
         */
        updateCategoryName(state, { oldCategory, newCategory }) {
            const category = state.categories.find(d => d.category === oldCategory);

            category.category = newCategory;
            category.fields.forEach(field => {
                field.definition.category = newCategory;
            });
            state.changed = true;

        },

        /**
         * @param {State} state
         * @param {string} category
         */
        toggleDeletedCategory(state, category) {
            state.categories = state.categories.map(d => {
                if (d.category !== category) { return d; }
                return { ...d, _to_delete: d._to_delete ? undefined : true };
            });
            state.changed = true;
        },

        /**
         * @param {State} state
         * @param {object} args
         * @param {string} args.key
         * @param {string} [args.value]
         */
        setRepresentationField(state, { key, value }) {
            if (!value) { return; }
            state.representationFields[key] = value;
            state.changed = true;
        },

        resetChanged(state) {
            state.changed = false;
        }
    },

    getters: {
        /** @type {(state: State) => () => string[]} */
        allFieldNames: state => () => state.categories.reduce((names, category) => {
            category.fields.forEach(({ field }) => names.push(field));
            return names;
        }, []),

        /** @type {(state: State) => (d: { categoryIndex: number, fieldIndex: number }) => CategoryField} */
        fieldDefinition: state => ({ categoryIndex, fieldIndex }) =>
            get(state.categories, [categoryIndex, 'fields', fieldIndex, 'definition']),

        /** @type {(state: State) => (d: { categoryIndex: number, fieldIndex: number }) => string} */
        fieldName: state => ({ categoryIndex, fieldIndex }) =>
            get(state.categories, [categoryIndex, 'fields', fieldIndex, 'field']),

        /** @type {(state: State) => (d: { categoryIndex: number, fieldIndex: number }) => number} */
        fieldRev: state => ({ categoryIndex, fieldIndex }) =>
            get(state.categories, [categoryIndex, 'fields', fieldIndex, '_rev'])
    },
};
